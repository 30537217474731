/* #slider {
  position: absolute;
  left: 50%;
  top: 0px;
  background-color: #D3D3D3;
  width: 4px;
  height: 100%;
  z-index: 9999;
}

#slider:hover {
  cursor: ew-resize;
} */

#slider {
  position: absolute;
  left: 50%;
  top: 0px;
  background-color: #d3d3d3;
  width: 5px;
  height: 100%;
  z-index: 9999;
}

#slider:hover {
  cursor: ew-resize;
}
