.two-column-container {
  display: flex;
  flex-grow: 1;
}

.first-column {
  flex: 30%;
  background-color: #ebecf5;
  flex: 1 1 auto;
  overflow-y: auto;
  height: 100vh;
  /* position: relative; */
  min-height: calc(100vh - 210px);
  /* padding: 10px; */
  /* overflow: auto; */
}

.second-column {
  flex: 70%;
  flex-grow: 1;
  position: relative;
  background-color: #ebecf5;

  /* display: flex; */
  /* padding: 20px; */

  /* padding: 20px; */
}

.globeWrapper {
  /* padding: 10px; */
  /* flex-grow: 1; */
}

.ViewerWrapper {
  background-color: red;
  /* height: 100px; */
  height: calc(100vh - (140px) - (70px));
  /* height: calc(100vh - (140px) - (70px)); */
  position: relative;
}
